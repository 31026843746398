import React from 'react';
import { IllustrativeIcon } from '../../atoms/icons/illustrative-icon/illustrative-icon';
import { StructuredProps } from '../../types';

const Data: StructuredProps = {
  alignment: 'center',
  heading: 'Our whole-body approach to postpartum healing.',
  items: [
    {
      id: 'hormonal-screening',
      heading: "We meet you where you're at.",
      description:
        'Our clinicians take the time to get to know you with an in-depth assessment to understand the connection between your pregnancy and health history, your lifestyle, and your unique postpartum symptoms.',
      illustration: <IllustrativeIcon color="sage" name="personal" />
    },
    {
      id: 'whole-body-approach',
      heading: 'Advanced testing for hormonal balance.',
      description:
        'It’s normal to experience postpartum hormone fluctuations after giving birth. We run advanced tests to check the status of your overall hormonal health so we can help you feel more like yourself again.',
      illustration: <IllustrativeIcon color="sage" name="testing" />
    },
    {
      id: 'personalized-ongoing-support',
      heading: 'A personalized health plan to heal.',
      description:
        'Your clinician and health coach will create a plan to help you rest and nourish your healing body. We will be there along the way, offering support for your mental wellbeing, support for your nutrition, and guidance for breastfeeding, sleep training, and more.',
      illustration: <IllustrativeIcon color="sage" name="teamCollab" />
    }
  ]
};

export default Data;
