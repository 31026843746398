import React from 'react';
import {
  ConditionsCollapsible,
  ContinuedTextListModule,
  FeaturedArticles,
  Hero,
  Module,
  Structured
} from '../../redesign';
import { CliniciansHalfAndHalf } from '../../redesign/pre-built-components';

import structuredContent from '../../redesign/content/postpartum/structured-understand';
import heroContent from '../../redesign/content/postpartum/hero.json';
import symptomsListContent from '../../redesign/content/postpartum/symptoms-list.json';
import doctorsSuccessfullyTreatContent from '../../redesign/content/postpartum/doctors-successfully-treat.json';
import blogPostContent from '../../redesign/content/postpartum/blog-post.json';
import { default as SEO } from '../../components/SEO';
import { Layout } from '../../redesign';

export default function PostpartumHealth() {
  return (
    <Layout>
      <SEO
        pageTitle="Holistic Postpartum Recovery | Postpartum Depression Care | Parsley Health"
        pageDescription="Supporting mothers after delivery is what we do. At Parsley Health, you'll get holistic postpartum care with treatments for all aspects of your health, whether this is your first baby or your third."
      />
      <Hero {...heroContent} />
      <Module>
        <ContinuedTextListModule {...symptomsListContent} />
      </Module>
      <Module>
        <ConditionsCollapsible {...doctorsSuccessfullyTreatContent} />
      </Module>
      <Module>
        <Structured {...structuredContent} />
      </Module>
      <Module>
        <CliniciansHalfAndHalf
          ctaTrackingLabel="Meet Our Clinicians button on the Postpartum page - Stress-free online holistic care module"
          eyebrow="The best postpartum support"
        />
      </Module>
      <Module>
        <FeaturedArticles {...blogPostContent} />
      </Module>
    </Layout>
  );
}
